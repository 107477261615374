import React, { useEffect } from "react";
import Select from "react-select";
import { uniqueArr } from "../helper";
import useStateWithLocalStorage from "./useStateWithLocalStorage";
import _ from "lodash";

export default ({
  name,
  options: _options,
  defaultValues: _defaultValues,
  onChange,
  editable,
  className,
  localStorageKey,
  placeholder
}) => {
  const [defaultValues, setDefaultValues] = React.useState(_defaultValues);

  useEffect(() => {
    if (!_.isEqual(_defaultValues, defaultValues)) {
      setDefaultValues(_defaultValues);
    }
  }, [_defaultValues, defaultValues]);
  const [temp, setTemp] = React.useState();

  const [
    customOptionsInLocalStorage,
    setCustomOptionsInLocalStorage
  ] = useStateWithLocalStorage(localStorageKey);

  const [customOptions, setCustomeOptions] = React.useState(
    customOptionsInLocalStorage &&
      typeof customOptionsInLocalStorage !== "undefined"
      ? JSON.parse(customOptionsInLocalStorage) || []
      : []
  );
  const [options, setOptions] = React.useState(
    // append some not exist in list options
    // unique to avoid duplicate value
    uniqueArr(
      (_options || [])
        .concat(placeholder)
        .concat(_defaultValues)
        .concat(customOptions)
    )
  );

  const customStyles = {
    container: provided => ({
      ...provided,
      minWidth: 400
    })
  };

  return (
    <Select
      styles={customStyles}
      value={defaultValues}
      isMulti
      name={name}
      options={options}
      placeholder={
        (options && options.length > 0 && options[0].value) ||
        "type to search or create by enter..."
      }
      className={`basic-multi-select ${className}`}
      classNamePrefix="select"
      onChange={selected => {
        setDefaultValues(selected);
        onChange(selected);
      }}
      onInputChange={e => {
        setTemp(e.trim());
      }}
      onKeyDown={() => {
        switch (window.event.keyCode) {
          case 13: // ENTER
            window.event.preventDefault();
            const exist = options.some(
              ({ value, label }) => value === temp || label === temp
            );
            if (!exist && temp !== "") {
              const newOption = { value: temp, label: temp };
              // defaultValues can be null
              if (defaultValues && typeof defaultValues !== "undefined") {
                setDefaultValues([...defaultValues, newOption]);
              }
              if (options && typeof options !== "undefined") {
                setOptions([...options, newOption]);
              }
              if (customOptions && typeof customOptions !== "undefined") {
                const newCustomOptions = [...customOptions, newOption];
                setCustomeOptions(newCustomOptions);

                setCustomOptionsInLocalStorage(
                  JSON.stringify(newCustomOptions)
                );
              }
            }
            onChange(defaultValues);
            break;
          default:
            break;
        }
      }}
      isDisabled={!editable}
    />
  );
};
