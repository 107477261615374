import { getFirstLogicTypeAndGroups, getInnerData } from "./reducerHelper";

const init = initialCount => {
  return { segmentData: initialCount };
};

const reducer = (state, action) => {
  const { firstLogicType, groups } = getFirstLogicTypeAndGroups(
    state.segmentData
  );

  switch (action.type) {
    case "firstLogicType":
      if (action.payload !== firstLogicType) {
        if (action.payload === "condition") {
          return {
            segmentData: getInnerData(groups)
          };
        } else {
          return {
            segmentData: {
              [action.payload]: getInnerData(groups)
            }
          };
        }
      }
      break;
    case "groups":
      if (firstLogicType === "condition") {
        return { segmentData: getInnerData(action.payload) };
      } else {
        return {
          segmentData: {
            [firstLogicType]: getInnerData(action.payload)
          }
        };
      }

    case "reset":
      return init(action.payload);
    default:
      return state;
  }
};

export { reducer, init, getFirstLogicTypeAndGroups };
