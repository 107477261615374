import React from "react";

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    localStorage && localStorage.getItem(localStorageKey)
  );

  React.useEffect(() => {
    localStorage && localStorage.setItem(localStorageKey, value);
  }, [value, localStorageKey]);

  return [value, setValue];
};

export default useStateWithLocalStorage;
