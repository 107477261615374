import { parse } from "url";

const getParams = url => {
  const params = {};
  const { query } = parse(url);
  const vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
};

const getSegmentId = () => {
  const pathname = window.location.pathname;
  return pathname.split("/").pop(0);
};

export { getParams, getSegmentId };
