import React from "react";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { useStyles } from "../../style";
import { getEvent, getCategory, isCustom } from "./conditionHelper";

const SelectCondition = ({
  conditions,
  setConditions,
  isNew,
  alreadyChosedCategory,
  originalMapConditions,
  notHide
}) => {
  const classes = useStyles();

  const hasValueConditions = conditions.filter(({ value }) => !!value);

  if (!notHide && (hasValueConditions.length > 0 || isCustom(conditions))) {
    return null;
  }

  return (
    <FormControl
      className={classes.margin}
      style={{ display: "block", margin: "0 0 10px 0" }}
    >
      <InputLabel>Select condition</InputLabel>
      <Select
        onChange={e => {
          if (!setConditions) {
            return null;
          }
          // if there is already user selected condition, then he can't change
          else {
            setConditions(originalMapConditions[e.target.value]);
          }
        }}
        value={
          isCustom(conditions)
            ? "custom"
            : getCategory(conditions) || getEvent(conditions)
        }
        style={{ minWidth: 200 }}
      >
        {Object.entries(originalMapConditions).map(([key, value]) => {
          let selected = getCategory(conditions) || getEvent(conditions);

          if (key === "custom") {
            selected = isCustom(conditions);
          }

          if (
            alreadyChosedCategory &&
            alreadyChosedCategory.findIndex(category => category === key) > -1
          ) {
            return null;
          }

          return (
            <MenuItem value={key} selected={selected} key={key}>
              {key}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectCondition;
