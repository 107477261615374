import React from "react";
import useConditions from "../../../hooks/useConditions";

const withOriginalMapConditions = C => {
  const InnerC = props => {
    const { mapConditions } = useConditions();

    if (Object.keys(mapConditions).length === 0) {
      return null;
    }

    return <C {...props} originalMapConditions={mapConditions} />;
  };

  return InnerC;
};

export default withOriginalMapConditions;
