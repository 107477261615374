// Changes XML to JSON
const xmlToJson = xml => {
  // Create the return object
  var obj = {};

  if (xml.nodeType === 1) {
    // element
    // do attributes
    if (xml.attributes.length > 0) {
      obj = {};
      for (var j = 0; j < xml.attributes.length; j++) {
        var attribute = xml.attributes.item(j);
        obj = { ...obj, [attribute.nodeName]: attribute.nodeValue };
      }
    }
  } else if (xml.nodeType === 3) {
    // text
    obj = xml.nodeValue;
  }

  // do children
  if (xml.hasChildNodes()) {
    for (var i = 0; i < xml.childNodes.length; i++) {
      var item = xml.childNodes.item(i);

      const childItems = [];

      Array.from(item.childNodes).filter(node => {
        if (node.nodeName !== "#text") {
          childItems.push(node);
          return true;
        } else {
          return false;
        }
      });

      if (item.nodeName !== "#text") {
        var nodeName = item.nodeName;

        if (childItems.length === 1) {
          obj[nodeName] = xmlToJson(item);
        } else if (childItems.length > 1) {
          // console.log("enter > 1");
          obj[nodeName] = [];
          for (let _item of childItems) {
            obj[nodeName].push({ [_item.nodeName]: xmlToJson(_item) });
          }
        } else if (typeof obj[nodeName] === "undefined") {
          obj[nodeName] = xmlToJson(item);
        } else {
          if (typeof obj[nodeName].push === "undefined") {
            var old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(xmlToJson(item));
        }
      }
    }
  }

  return obj;
};

const JsonToXML = obj => {
  let xml = "";

  const conditionXML = item => {
    xml += `\t\t\t<condition`;
    Object.entries(item).forEach(([key, value]) => {
      if (value !== "") {
        xml += ` ${key}="${value}" `;
      }
    });
    xml += "/>\n";
  };

  for (let prop in obj) {
    if (obj[prop] instanceof Array) {
      if (prop !== "condition") {
        xml += `\t\t<${prop}>\n`;
        xml += JsonToXML({ ...obj[prop] });
        xml += `\t\t</${prop}>\n`;
      } else {
        obj[prop]
          .filter(({ value }) => !!value)
          .forEach(item => {
            conditionXML(item);
          });
      }
    } else {
      if (prop !== "condition") {
        xml += `\t<${prop}>\n`;
        typeof obj[prop] == "object"
          ? (xml += JsonToXML({ ...obj[prop] }))
          : (xml += obj[prop]);
        xml += `\t</${prop}>\n`;
      } else {
        // only one condition
        conditionXML(obj[prop]);
      }
    }
  }

  xml = xml.replace(/<\/?[0-9]{1,}>/g, "");
  return xml;
};

export { xmlToJson, JsonToXML };
