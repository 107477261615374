import React from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const withConfirmModal = C => {
  const InnerC = props => {
    const [open, setOpen] = React.useState(props.open || false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <C
        {...props}
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />
    );
  };

  return InnerC;
};

const ConfirmModal = ({
  title,
  content,
  open,
  handleClose,
  handleConfirm,
  errorModal
}) => (
  <Dialog
    fullWidth
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    {content && (
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
    )}
    {errorModal ? (
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    ) : (
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button
          onClick={errorModal ? handleClose : handleConfirm}
          color="primary"
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    )}
  </Dialog>
);

const ErrorModal = ({ error }) => {
  const { msg } = error;
  const InnerC = withConfirmModal(ConfirmModal);

  return <InnerC title="error occur" content={msg} errorModal open />;
};

export default ConfirmModal;
export { withConfirmModal, ErrorModal };
