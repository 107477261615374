import React, { useState, useEffect } from "react";
import MainLayout from "../../components/MainLayout";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination
} from "@material-ui/core";
import styled from "styled-components";
import Loading from "../../components/Loading";
import { SEGMENT_ITEM_DETAIL_URL } from "../../Utils";
import EnhancedTableHead from "./molecules/TableHead";
import { getSegments } from "./api";
import New from "./molecules/New";
import { stableSort, getSorting } from "../ELP/TableSorting";
import { ErrorModal } from "./molecules/ConfirmModal";

const Container = styled(Paper)`
  padding: 0;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1) !important;
  thead th {
    text-align: left;
    padding-left: 24px;
  }
  tbody tr {
    cursor: pointer;
    &:hover {
      background: #f9fafb;
    }
  }
`;

const CenterLoading = styled.div`
  > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-left: 120px;
  }
`;

const rowsPerPage = 10;

const ListItems = () => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState({ order: "asc", orderBy: "name" });
  const { order, orderBy } = sort;
  const [error, setError] = useState(null);

  const handleClick = segment_id => {
    const detailsPageURI = `${SEGMENT_ITEM_DETAIL_URL}${segment_id}`;
    window.location.assign(detailsPageURI);
  };

  const handleRequestSort = domain => {
    const { order, orderBy } = sort;
    const isDesc = orderBy === domain && order === "desc";
    setSort({ order: isDesc ? "asc" : "desc", orderBy: domain });
  };

  useEffect(() => {
    getSegments()
      .then(({ data }) => {
        setRows(data);
      })
      .catch(e => {
        const error = e.response.data;
        setError(error);
      });
  }, []);

  const onChangePage = (_, page) => setPage(page);

  if (error) {
    return <ErrorModal error={error} />;
  }

  return (
    <>
      {!rows ? (
        <CenterLoading>
          <Loading />
        </CenterLoading>
      ) : (
        <div>
          {rows.length ? (
            <>
              <Container>
                <Table aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    sort={sort}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(row => (
                        <TableRow
                          key={row.id}
                          onClick={() => handleClick(row.id)}
                        >
                          <TableCell scope="row">{row.name}</TableCell>
                          <TableCell>{row.visitor_count}</TableCell>
                          <TableCell>
                            {new Date(row.updated_at).toDateString()}
                          </TableCell>
                          <TableCell>
                            {new Date(row.created_at).toDateString()}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                {rows.length > rowsPerPage && (
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      "aria-label": "Previous Page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page"
                    }}
                    onChangePage={onChangePage}
                  />
                )}
              </Container>
              <New inList />
            </>
          ) : (
            <New />
          )}
        </div>
      )}
    </>
  );
};

const Segments = () => (
  <MainLayout pageTitle="Segments">
    <ListItems />
  </MainLayout>
);

export default Segments;
