import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  TextField,
  InputLabel
} from "@material-ui/core";
import { CREATE_SEGMENT_URL } from "../../../Utils";
import { useStyles } from "../style";

const AddModal = ({ open, setOpen }) => {
  const classes = useStyles();
  const [name, setName] = useState();

  return (
    <Dialog
      fullScreen={false}
      open={open}
      aria-labelledby="responsive-dialog-title"
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
    >
      <DialogContent className={classes.content}>
        <form
          onSubmit={e => {
            e.preventDefault();
            window.location.href = CREATE_SEGMENT_URL + `?name=${name}`;
          }}
        >
          <div>
            <InputLabel name="segmentName">Segment Name?</InputLabel>
            <TextField
              placeholder="input your segment name"
              defaultValue={name}
              name="segmentName"
              onChange={e => setName(e.target.value.trim())}
              max-length={100}
              min-length={1}
              required
              type="string"
            />
          </div>
          <br />
          <Button type="submit" color="primary" className={classes.addBtn}>
            Let's start!
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddModal;
