import React, { useState, useEffect } from "react";
import { getParams } from "./helpers/pageHelper";
import { Button } from "@material-ui/core";
import MainLayout from "../../components/MainLayout";
import {
  SEGMENTS_LISTS_URL,
  SEGMENT_ITEM_DETAIL_URL,
  BOOST__PERMISSIONS
} from "../../Utils";
import FormViewOrCodeView from "./molecules/FormViewOrCodeView";
import { getSegmentItem } from "./api";
import { useStyles } from "./style";
import { updateSegment } from "./api";
import { getSegment, getFinalScript } from "./helpers/segmentHelper";
import useFormOrCodeView from "./hooks/useFormOrCodeView";
import { DetailHeaderWrapper, ContentWrapper } from "./atoms/index";
import { ErrorModal } from "./molecules/ConfirmModal";
import { cannotSave } from "./molecules/FormViewOrCodeView/hooks/reducerHelper";
import { SegmentDataContext } from "./hooks/SegmentContext";

const Edit = () => {
  const classes = useStyles();

  const [segment, setSegment] = useState();
  const [error, setError] = useState();
  const [script, setScript] = useState();
  const [segmentData, setSegmentData] = useState([]);
  const { view, setFormView, setCodeView } = useFormOrCodeView();

  const switchToCodeView = () => {
    alert("this will lost all empty conditions");
    setScript(getFinalScript({ segmentData, view, script }));
    setCodeView();
  };

  const switchToFormView = () => {
    setSegmentData(getSegment(script));
    setFormView();
  };

  const onSubmit = () => {
    updateSegment({
      data: {
        name: segment.name,
        script: getFinalScript({ segmentData, view, script }).replace(
          /[\r\n]/g,
          ""
        )
      },
      segmentId: segment.id
    })
      .then(() => {
        window.location.href = SEGMENT_ITEM_DETAIL_URL + segment.id;
      })
      .catch(e => {
        setError(e);
      });
  };

  const onDiscard = () => {
    window.location.href = SEGMENT_ITEM_DETAIL_URL + segment.id;
  };

  useEffect(() => {
    const { href } = window.location;
    const query = getParams(href);
    const segmentId = query["id"];

    getSegmentItem(segmentId)
      .then(({ data }) => {
        setSegment(data);
        setScript(data.script);
        setSegmentData(getSegment(data.script));
      })
      .catch(e => {
        const error = e.response.data;
        setError(error);
      });
  }, []);

  if (error) {
    return <ErrorModal error={error} />;
  }

  if (!segment || !segment.script) {
    return null;
  }

  if (!BOOST__PERMISSIONS.edit) {
    return (
      <>
        <h1>Sorry, you can only read, cannot write the content!</h1>
        <p>
          click <a href={SEGMENT_ITEM_DETAIL_URL + segment.id}>here</a> to go
          back to read content
        </p>
      </>
    );
  }

  if (segmentData.length === 0) {
    return null;
  }

  return (
    <>
      <DetailHeaderWrapper position="static">
        <h1>EDIT: {segment.name}</h1>
        <div>
          <Button
            className={classes.saveBtn}
            onClick={onSubmit}
            disabled={cannotSave(segmentData)}
          >
            save
          </Button>
          <Button className={classes.discardBtn} onClick={onDiscard}>
            discard
          </Button>
        </div>
      </DetailHeaderWrapper>
      <ContentWrapper>
        <SegmentDataContext.Provider value={{ segmentData, setSegmentData }}>
          <FormViewOrCodeView
            script={script}
            setScript={setScript}
            isEdit
            view={view}
            setFormView={switchToFormView}
            editable
            switchToCodeView={switchToCodeView}
          />
        </SegmentDataContext.Provider>
      </ContentWrapper>
    </>
  );
};

const Segment = () => (
  <MainLayout pageTitle="Segments" pageBackUrl={SEGMENTS_LISTS_URL}>
    <Edit />
  </MainLayout>
);

export default Segment;
