const opsMap = {
  eq: "eq",
  neq: "neq",
  containsany: "containsany",
  notcontainsany: "notcontainsany",
  gt: "gt",
  gte: "gte",
  lt: "lt",
  lte: "lte"
};

const ops = [
  "eq",
  "neq",
  "containsany",
  "notcontainsany",
  "gt",
  "gte",
  "lt",
  "lte"
];
const offsetOps = ["gt", "lt"];

const getOps = (isOffset, operations) =>
  isOffset ? offsetOps : operations ? operations.split(",") : ops;

const getOPText = (op, isOffset) => {
  if (isOffset) {
    switch (op) {
      case "gt":
        return "Within the last few "; // > 1: within 1 day
      case "lt":
        return "Before the last few "; // < 1: before 1 day
      default:
        return null;
    }
  } else {
    switch (op) {
      case "eq":
        return "Equal";
      case "neq":
        return "Not Equal";
      case "containsany":
        return "Contains one of";
      case "notcontainsany":
        return "Not contain each of";
      case "gt":
        return "Is more than";
      case "gte":
        return "Is more than or equal than";
      case "lt":
        return "Is less than";
      case "lte":
        return "Is less than or equal than";
      default:
        return null;
    }
  }
};

const types = ["String", "Integer", "EpochTimeMS", "LocalDate"];

export { getOps, types, getOPText, opsMap };
