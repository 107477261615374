import React from "react";
import Info from "./atoms/Info";
import AceEditor from "react-ace";
// ace mode and theme
import "brace";
import "brace/mode/xml";
import "brace/theme/monokai";
var format = require("xml-formatter");

const CodeView = ({ value, onChange, isNew }) => {
  const defaultValue = value ? (isNew ? value : `${format(value)}`) : "";
  const [codeViewValue, setCodeViewValue] = React.useState(defaultValue);

  React.useEffect(() => {
    setCodeViewValue(defaultValue);
  }, [defaultValue]);

  const save = () => {
    if (onChange) {
      onChange(codeViewValue);
    }
  };

  return (
    <>
      {onChange && (
        <>
          <Info>Must set condition event and path, auto save when on blur</Info>
          <Info>
            Please fill the value if you want to save, otherwise, empty value
            will be cleaned after switch to form view or click save
          </Info>
        </>
      )}
      <AceEditor
        placeholder="Placeholder Text"
        mode="xml"
        theme="monokai"
        name="blah2"
        onChange={setCodeViewValue}
        fontSize={14}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        value={codeViewValue}
        setOptions={{
          showLineNumbers: true,
          tabSize: 2,
          readOnly: !onChange
        }}
        style={{
          width: "100%"
        }}
        onBlur={save}
      />
    </>
  );
};

export default CodeView;
