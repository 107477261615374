import React from "react";
import {
  Input,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem
} from "@material-ui/core";
import styled from "styled-components";
import { useStyles } from "../../../../style";
import { getOps, getOPText, types } from "../../constances";
import { getCategory, getEvent, isCustom } from "../../conditionHelper";
import SelectCondition from "../../SelectCondition";
import { useNewConditionForm } from "./hooks";
import { EventLabel } from "../../atoms/EventLabel";
import InputGroup from "./InputGroup";

const Title = styled.span`
  background: mediumseagreen;
  color: white;
  padding: 5px;
  border-radius: 5%;
  cursor: pointer;
  margin-right: 5px;
`;

const formItemStyle = { minWidth: 200, marginTop: 10, marginBottom: 10 };

const NewSingleCondition = ({
  conditions,
  setConditions,
  isCustom,
  category,
  setStartAdd
}) => {
  const classes = useStyles();

  const {
    addCondition,
    handleChange,
    addNewCondition,
    disabled
  } = useNewConditionForm({
    conditions,
    setConditions,
    isCustom,
    category
  });

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation(); // stop bubble up to trigger other form
        addNewCondition();
        setStartAdd && setStartAdd(false);
      }}
    >
      <InputLabel>Add new condition</InputLabel>
      <FormControl className={classes.margin} style={formItemStyle}>
        <InputLabel>Category</InputLabel>
        <Input
          value={addCondition.category}
          name="category"
          onChange={handleChange}
          required
        />
      </FormControl>
      <FormControl className={classes.margin} style={formItemStyle}>
        <InputLabel>Title</InputLabel>
        <Input
          value={addCondition.title}
          name="title"
          onChange={handleChange}
          required
        />
      </FormControl>
      <FormControl className={classes.margin} style={formItemStyle}>
        <InputLabel>Subtitle</InputLabel>
        <Input
          value={addCondition.subtitle}
          name="subtitle"
          onChange={handleChange}
        />
      </FormControl>
      <FormControl className={classes.margin} style={formItemStyle}>
        <InputLabel>Event</InputLabel>
        <Input
          value={addCondition.event}
          name="event"
          onChange={handleChange}
          required
        />
      </FormControl>
      <FormControl className={classes.margin} style={formItemStyle}>
        <InputLabel>Path</InputLabel>
        <Input
          value={addCondition.path}
          name="path"
          onChange={handleChange}
          required
        />
      </FormControl>
      <FormControl className={classes.margin} style={formItemStyle}>
        <InputLabel>Type</InputLabel>
        <Select
          name="type"
          value={addCondition.type}
          onChange={handleChange}
          required
        >
          {types.map(type => (
            <MenuItem
              value={type}
              selected={type === addCondition.type}
              key={type}
            >
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.margin} style={formItemStyle}>
        <InputLabel>Op</InputLabel>
        <Select name="op" value={addCondition.op} onChange={handleChange}>
          {getOps(addCondition.isOffset).map(_op => (
            <MenuItem value={_op} selected={_op === addCondition.op} key={_op}>
              {getOPText(_op)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.margin} style={formItemStyle}>
        <InputLabel>Value</InputLabel>
        <Input
          value={addCondition.value}
          name="value"
          onChange={handleChange}
          required
        />
      </FormControl>
      <div>
        <Button className={classes.addBtn} type="submit" disabled={disabled}>
          add
        </Button>
      </div>
    </form>
  );
};

const New = ({
  conditions,
  setConditions,
  editable,
  isNew,
  originalMapConditions
}) => {
  let display = true;
  const classes = useStyles();

  const [newConditions, setNewConditions] = React.useState([]);
  const [startAdd, setStartAdd] = React.useState(false);

  if (isNew) {
    display = true;
  }

  const target = getCategory(conditions) || getEvent(conditions);

  if (!target && target !== "") {
    display = false;
  }

  if (!editable) {
    display = false;
  }

  if (!display) {
    return null;
  }

  const addNewConditions = () => {
    const _newConditions = newConditions.filter(({ value }) => !!value);
    if (_newConditions) {
      setConditions(conditions.concat(_newConditions));
      reset();
    }
  };

  const reset = () => {
    setNewConditions([]);
    setStartAdd(false);
  };

  const alreadyChosedCategory = [];

  conditions.forEach(({ category }) => {
    if (alreadyChosedCategory.findIndex(e => e === category) > -1) {
      return false;
    }
    alreadyChosedCategory.push(category);
  });

  if (
    Object.entries(originalMapConditions).length ===
    alreadyChosedCategory.length
  ) {
    return null;
  }

  const newConditionCategory = newConditions && getCategory(newConditions);

  return (
    <div style={{ width: "100%" }}>
      {startAdd ? (
        <>
          <SelectCondition
            conditions={newConditions}
            setConditions={setNewConditions}
            isNew={isNew}
            alreadyChosedCategory={alreadyChosedCategory}
            originalMapConditions={originalMapConditions}
            notHide
          />
          {newConditions.length > 0 ? (
            isCustom(newConditions) ? (
              <NewSingleCondition
                conditions={conditions}
                setConditions={setConditions}
                isCustom={isCustom(newConditions)}
                category={getCategory(conditions)}
                setStartAdd={setStartAdd}
              />
            ) : (
              <List>
                {newConditionCategory && (
                  <EventLabel>{newConditionCategory}</EventLabel>
                )}
                {newConditions
                  .filter(({ category, event }) => {
                    const target = category || event;
                    return !!target && target !== "";
                  })
                  .map((condition, index) => (
                    <ListItem
                      key={`${condition.category}-${condition.event}-${condition.path}-${index}`}
                    >
                      <InputGroup
                        condition={condition}
                        setConditions={setNewConditions}
                        editable={editable}
                      />
                    </ListItem>
                  ))}
                {newConditions.length > 0 ? (
                  <Button
                    className={classes.addBtn}
                    onClick={addNewConditions}
                    disabled={
                      newConditions.filter(({ value }) => !!value).length === 0
                    }
                  >
                    Add
                  </Button>
                ) : null}
              </List>
            )
          ) : null}
        </>
      ) : null}

      {newConditions && newConditions.length > 0 ? (
        <Title onClick={reset}>Cancel add new conditions</Title>
      ) : (
        <Title onClick={() => setStartAdd(true)}>Add new conditions</Title>
      )}
    </div>
  );
};

export default New;

export { NewSingleCondition };
