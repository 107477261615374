import React from "react";
import { useSegmentData } from "../hooks/useSegmentData";

const withSegementData = C => {
  const InnerC = props => {
    const data = useSegmentData();

    if (!data) {
      return null;
    }

    return <C {...props} data={data} />;
  };

  return InnerC;
};

export default withSegementData;
