import React from "react";
import { Typography, Select, MenuItem } from "@material-ui/core";
import { getOps, getOPText } from "../../constances";
const style = { minWidth: 200 };

const Op = ({ condition, editable, isOffset, handleChange }) => {
  if (!editable && condition.op) {
    return (
      <Typography style={style}>{getOPText(condition.op, isOffset)}</Typography>
    );
  }

  const handleOnChange = condition => e => {
    const { value, name } = e.target;
    handleChange({ name, value, condition });
  };

  const ops = getOps(isOffset, condition.operations);
  const defaultValue = condition.op
    ? condition.op
    : ops.length > 0
    ? ops[0]
    : null;

  return (
    <Select
      style={style}
      name="op"
      value={defaultValue}
      onChange={handleOnChange(condition)}
    >
      {ops.map(op => (
        <MenuItem value={op} selected={condition.op === op} key={op}>
          {getOPText(op, isOffset)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default Op;
