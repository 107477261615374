import { useEffect, useState } from "react";

import { getConditions } from "../api";
import { groupBy } from "../molecules/FormViewOrCodeView/helper";

const customMapConditions = { custom: [{ event: "", isCustom: true }] };

const useMapConditions = () => {
  const [mapConditions, setMapConditions] = useState([]);

  useEffect(() => {
    getConditions().then(({ code, data }) => {
      if (code === 200 && data) {
        let conditionsFromAPI = data.map(c => {
          let op;
          if (c.operations === "eq") {
            op = "eq";
          } else if (c.operations && c.operations.split(",").length > 0) {
            op = c.operations.split(",")[0];
          }

          return {
            ...c,
            value: c.defaultValue,
            op,
            type: c.types.split(",")
          };
        });

        const mapConditions = {
          ...groupBy(conditionsFromAPI, "category"),
          ...customMapConditions
        };

        setMapConditions(mapConditions);
      }
    });
  }, []);

  return { mapConditions };
};

export default useMapConditions;
