import React, { useState, useCallback } from "react";
import styled from "styled-components";
import withSegementData from "./hoc/withSegmentData";
import withMapConditions from "./hoc/withOriginalMapConditions";
import Selector from "./atoms/Selector";
import Button from "@material-ui/core/Button";
import Wrapper from "./atoms/Wrapper";
import { List, ListItem as _ListItem } from "@material-ui/core";
import { useStyles } from "../../style";
import Collapse from "@material-ui/core/Collapse";

const ListItem = styled(_ListItem)`
  // make all item width to be 100%
  &&& > div,
  &&& > ul,
  &&& > form {
    width: 100%;
  }
`;

const withStep = C => {
  const logicTypes = ["and", "nand", "or", "nor"];
  const conditionOrlogicTypes = ["condition"].concat(logicTypes);

  const PassC = ({
    conditionOrLogic,
    condition,
    index,
    handleChildChange,
    props,
    notHideSelector
  }) => {
    const onChange = useCallback(
      conditions => {
        handleChildChange({
          value: conditions,
          name: "condition",
          index
        });
      },
      [index, handleChildChange]
    );

    return conditionOrlogicTypes.findIndex(v => v === conditionOrLogic) > -1 ? (
      <C
        {...props}
        conditions={
          !condition // when condition is undefined when new add
            ? []
            : conditionOrLogic === "condition"
            ? condition instanceof Array
              ? condition
              : [condition]
            : condition
        }
        onChange={onChange}
        notHideSelector={notHideSelector}
      />
    ) : null;
  };

  const NewGroup = ({ groups, updateGroups, props, firstLogicType }) => {
    const classes = useStyles();
    const initGroup = {};
    const [newGroup, setNewGroup] = useState(initGroup);
    const { conditionOrLogic, condition } = newGroup;
    const [displaySelect, setDisplaySelect] = useState(false);

    const onChange = e => {
      const { name, value } = e.target;

      setNewGroup({
        ...newGroup,
        [name]: value
      });
    };

    const handlePassCChange = ({ name, value }) => {
      setNewGroup({ ...newGroup, [name]: value });
    };

    const addNewGroups = () => {
      if (conditionOrLogic === "condition") {
        let data = [];

        newGroup.condition
          .filter(({ value }) => !!value)
          .forEach(c => {
            data.push({ conditionOrLogic, condition: c });
          });

        updateGroups([...groups, ...data]);
      } else {
        const newCondition = newGroup.condition.filter(({ value }) => !!value);

        updateGroups([...groups, { ...newGroup, condition: newCondition }]);
      }

      reset();
    };

    const reset = () => {
      setNewGroup(initGroup);
      setDisplaySelect(false);
    };

    const disabled =
      !newGroup.conditionOrLogic ||
      !newGroup.condition ||
      newGroup.condition.filter(({ value }) => !!value).length === 0;

    return (
      <form
        onSubmit={e => {
          e.preventDefault();
          addNewGroups();
        }}
      >
        {/* step 2 */}
        {displaySelect ? (
          <div>
            <Selector
              title="Condition/Logic"
              name="conditionOrLogic"
              value={conditionOrLogic}
              onChange={onChange}
              items={conditionOrlogicTypes}
              canEdit={true}
              firstLogicType={firstLogicType}
            ></Selector>

            <PassC
              condition={condition}
              conditionOrLogic={conditionOrLogic}
              handleChildChange={handlePassCChange}
              props={props}
              notHideSelector={true}
            />
            {disabled ? null : (
              <Button type="submit" className={classes.addBtn}>
                Confirm
              </Button>
            )}
          </div>
        ) : null}
        <Button
          className={classes.addBtn}
          onClick={() => setDisplaySelect(true)}
        >
          Add new Group
        </Button>
        {condition ? (
          <Button className={classes.discardBtn} onClick={reset}>
            Cancel add new group
          </Button>
        ) : null}
      </form>
    );
  };

  const Group = ({
    firstLogicType,
    conditionOrLogic,
    condition,
    handleChange,
    handleChildChange,
    index,
    canEdit,
    props
  }) => {
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
      setOpen(!open);
    };

    return (
      <div>
        {conditionOrLogic ? (
          <Selector
            title="Condition/Logic"
            name="conditionOrLogic"
            value={conditionOrLogic}
            onChange={handleChange(index)}
            items={conditionOrlogicTypes}
            canEdit={canEdit}
            clickToCollapseList={handleClick}
            listIsOpen={open}
            firstLogicType={firstLogicType}
          ></Selector>
        ) : null}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <PassC
            condition={condition}
            conditionOrLogic={conditionOrLogic}
            index={index}
            handleChildChange={handleChildChange}
            props={props}
          />
        </Collapse>
      </div>
    );
  };

  const InnerC = ({ data, ...props }) => {
    const {
      firstLogicType,
      groups,
      setFirstLogicType,
      handleChange,
      handleChildChange,
      updateGroups
    } = data;
    const { isNew, editable } = props;

    const canEdit = isNew || editable;

    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
      setOpen(!open);
    };

    return (
      <Wrapper>
        {/* step 1 */}
        <Selector
          value={firstLogicType}
          items={logicTypes}
          name="firstLogicType"
          onChange={setFirstLogicType}
          style={{ width: "100%" }}
          title="First Logic/Condition"
          canEdit={canEdit}
          clickToCollapseList={handleClick}
          listIsOpen={open}
        />
        {firstLogicType ? (
          <>
            {groups && groups.length > 0 ? (
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List>
                  {groups.map(({ conditionOrLogic, condition }, index) => (
                    <ListItem
                      key={`${firstLogicType}-${conditionOrLogic}-${index}`}
                    >
                      <Group
                        firstLogicType={firstLogicType}
                        conditionOrLogic={conditionOrLogic}
                        condition={condition}
                        handleChange={handleChange}
                        handleChildChange={handleChildChange}
                        index={index}
                        canEdit={canEdit}
                        props={props}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            ) : null}
            {canEdit ? (
              <ListItem>
                <NewGroup
                  groups={groups}
                  updateGroups={updateGroups}
                  props={props}
                  canEdit={canEdit}
                  firstLogicType={firstLogicType}
                />
              </ListItem>
            ) : null}
          </>
        ) : null}
      </Wrapper>
    );
  };

  return withSegementData(withMapConditions(InnerC));
};

export { withStep };
