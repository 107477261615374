import React, { useState } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddModal from "./addModal";
import styled from "styled-components";
import { BOOST__PERMISSIONS } from "../../../Utils";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ inList }) => (inList ? "row" : "column")};

  ${({ inList }) =>
    inList &&
    `
    position: fixed;
    bottom: 24px;
    right: 24px; 
  `}
`;

const normalStyle = {
  color: "#ff9800",
  backgroundColor: "#fff",
  width: 100,
  height: 100,
  borderRadius: 100
};

const inlistStyle = {
  ...normalStyle,
  width: 40,
  height: 40
};

const New = ({ inList }) => {
  const [open, setOpen] = useState(false);

  if (!BOOST__PERMISSIONS.edit) {
    return null;
  }

  return (
    <Wrapper inList={inList}>
      <AddCircleIcon
        style={inList ? inlistStyle : normalStyle}
        onClick={() => {
          setOpen(true);
        }}
      />
      <div>{inList ? "New segment" : "Add my first segmentation"}</div>
      {open && <AddModal open={open} setOpen={setOpen} />}
    </Wrapper>
  );
};

export default New;
