import React, { useEffect } from "react";
import styled from "styled-components";
import { Typography, TextField } from "@material-ui/core";

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const OffsetInput = props => {
  const { condition, disabled, editable, handleChange } = props;
  const [days, setDays] = React.useState(condition.value);
  const unit = "d";

  useEffect(() => {
    if (days !== condition.value) {
      setDays(condition.value);
    }
  }, [condition.value, days]);

  let displayValue = days && days.substring(1).slice(0, -1);
  if (Number(days) && typeof Number(days) === "number") {
    displayValue = days;
  }

  if (!editable && displayValue) {
    return <Typography>{displayValue} days</Typography>;
  }

  const handleOnChange = condition => e => {
    const { name, value } = e.target;
    let newDays = value;
    if (Number(value) && typeof Number(value) === "number") {
      newDays = `-${value}${unit}`;
    }
    setDays(newDays);

    handleChange({
      name,
      value: typeof value === "undefined" || value === "" ? undefined : newDays,
      condition
    });
  };

  return (
    <Wrapper>
      <TextField
        name="value"
        value={(days && displayValue) || ""}
        placeholder={String(condition.placeholder)}
        disabled={disabled}
        onChange={handleOnChange(condition)}
      />
      <span>days</span>
    </Wrapper>
  );
};

const Input = props => {
  const { condition, disabled, editable, isOffset, handleChange } = props;

  if (isOffset) {
    return <OffsetInput {...props} key={props.condition.event} />;
  }

  if (!editable && condition.value) {
    return <Typography>{condition.value}</Typography>;
  }

  const handleOnChange = condition => e => {
    const { name, value } = e.target;
    handleChange({ name, value, condition });
  };

  return (
    <TextField
      name="value"
      value={condition.value || ""}
      placeholder={String(condition.placeholder)}
      onChange={handleOnChange(condition)}
      disabled={disabled}
    />
  );
};

export { OffsetInput };
export default Input;
