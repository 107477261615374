import React from "react";
import DateFnsUtils from "@date-io/date-fns"; // must be v1, see https://material-ui-pickers.dev/getting-started/installation#peer-library
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

const BasicDatePicker = ({ date, placeholder, onChange, editable }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
      id="date-picker-dialog"
      format="MM/dd/yyyy"
      value={date || null} // set null, otherwise it will use now
      KeyboardButtonProps={{
        "aria-label": "change date"
      }}
      onChange={onChange}
      readOnly={!editable}
      placeholder={placeholder}
    />
  </MuiPickersUtilsProvider>
);

const getSavedDate = date => {
  if (!date) {
    return "";
  }
  const year = date.getFullYear();
  let month = date.getMonth();
  if (month < 9) {
    month = `0${month + 1}`;
  } else {
    month = `${month + 1}`;
  }

  let day = date.getDate();
  if (day < 10) {
    day = `0${day}`;
  }

  return `${year}-${month}-${day}`;
};

const displayDatePicker = ({ type }) => type === "DatePicker";

const DatePicker = ({ condition, editable, handleChange }) => {
  const handleOnChange = date => {
    handleChange({ name: "value", value: getSavedDate(date), condition });
  };

  return (
    <BasicDatePicker
      date={condition.value && new Date(condition.value)}
      name="value"
      onChange={handleOnChange}
      placeholder={condition.placeholder}
      editable={editable}
    />
  );
};

export default DatePicker;
export { displayDatePicker };
