import React, { useEffect, useState } from "react";
import MainLayout from "../../components/MainLayout";
import { getSegmentItem } from "./api";
import { getSegmentId } from "./helpers/pageHelper";
import {
  SEGMENTS_LISTS_URL,
  UPDATE_SEGMENT_URL,
  BOOST__PERMISSIONS
} from "../../Utils";
import { Button } from "@material-ui/core";
import FormViewOrCodeView from "./molecules/FormViewOrCodeView";
import { useStyles } from "./style";
import { getSegment } from "./helpers/segmentHelper";
import useFormOrCodeView from "./hooks/useFormOrCodeView";
import { DetailHeaderWrapper, ContentWrapper } from "./atoms/index";
import { ErrorModal } from "./molecules/ConfirmModal";
import { SegmentDataContext } from "./hooks/SegmentContext";

const Detail = () => {
  const [segment, setSegment] = useState(null);
  const [error, setError] = useState(null);
  const classes = useStyles();
  const [script, setScript] = useState();
  const { view, setFormView, setCodeView } = useFormOrCodeView();

  useEffect(() => {
    const segmentId = getSegmentId();
    getSegmentItem(segmentId)
      .then(({ data }) => {
        setSegment(data);
        setScript(data.script);
      })
      .catch(e => {
        const error = e.response.data;
        setError(error);
      });
  }, []);

  if (error) {
    return <ErrorModal error={error} />;
  }

  if (!segment) {
    return <div>detail</div>;
  }

  const segmentData = getSegment(script);

  if (segmentData.length === 0) {
    return null;
  }

  return (
    <>
      <DetailHeaderWrapper>
        <h1>{segment.name}</h1>
        {BOOST__PERMISSIONS.edit ? (
          <Button
            className={classes.editBtn}
            onClick={() => {
              window.location.href = UPDATE_SEGMENT_URL + `?id=${segment.id}`;
            }}
            disabled={!BOOST__PERMISSIONS.edit}
          >
            edit
          </Button>
        ) : null}
      </DetailHeaderWrapper>
      <ContentWrapper>
        <SegmentDataContext.Provider value={{ segmentData }}>
          <FormViewOrCodeView
            script={script}
            setScript={setScript}
            view={view}
            setFormView={setFormView}
            switchToCodeView={setCodeView}
          />
        </SegmentDataContext.Provider>
      </ContentWrapper>
    </>
  );
};

const Segment = () => (
  <MainLayout pageTitle="Segments" pageBackUrl={SEGMENTS_LISTS_URL}>
    <Detail />
  </MainLayout>
);

export default Segment;
