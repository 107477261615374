import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { EventLabel } from "./atoms/EventLabel";
import SelectCondition from "./SelectCondition";
import New, { NewSingleCondition } from "./molecules/Conditions/New";
import { List, ListItem as _ListItem } from "@material-ui/core";
import { withStep } from "./withStep";
import * as _ from "lodash";
import { usePrevious } from "../../hooks/usePrevious";
import { groupBy } from "./helper";
import { getCategory, getDisplayConditions, isCustom } from "./conditionHelper";
import InputGroup from "./molecules/Conditions/InputGroup";

const ListItem = styled(_ListItem)`
  // make all item width to be 100%
  &&& > div,
  &&& > ul,
  &&& > form {
    width: 100%;
  }
`;

const FormView = ({
  editable,
  isNew,
  conditions: _conditions,
  onChange,
  originalMapConditions,
  notHideSelector
}) => {
  const [conditions, setConditions] = useState(_conditions);

  const myPreviousState = usePrevious(conditions);
  const readonly = !(isNew || editable);

  useEffect(() => {
    if (onChange) {
      if (myPreviousState && !_.isEqual(myPreviousState, conditions)) {
        onChange(conditions);
      }
    }
  }, [conditions, myPreviousState, onChange]);

  let mapConditions = groupBy(conditions, "category");

  if (Object.keys(mapConditions).findIndex(v => v === "undefined") > -1) {
    mapConditions = groupBy(conditions, "event");
  }

  return (
    <>
      {readonly ? null : (
        <SelectCondition
          conditions={conditions}
          setConditions={setConditions}
          isNew={isNew}
          originalMapConditions={originalMapConditions}
          notHide={notHideSelector}
        />
      )}
      {conditions.length > 0 && (
        <List style={{ borderLeft: "1px solid #ff9800" }}>
          {Object.entries(mapConditions).map(([key, value], index) => (
            <ListItem
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start"
              }}
              key={`${key}-${index}`}
            >
              {key && <EventLabel>{key}</EventLabel>}
              <List>
                {getDisplayConditions(value, originalMapConditions)
                  .filter(
                    ({ category, event }) =>
                      (!!category && category !== "") ||
                      (!!event && event !== "")
                  )
                  .map(condition => (
                    <ListItem
                      key={`${condition.event}-${condition.path}-${index}`}
                    >
                      <InputGroup
                        condition={condition}
                        setConditions={setConditions}
                        editable={editable}
                      />
                    </ListItem>
                  ))}
              </List>
            </ListItem>
          ))}
          {readonly ? null : (
            <>
              {isCustom(conditions) ? (
                <ListItem>
                  <NewSingleCondition
                    conditions={conditions}
                    setConditions={setConditions}
                    isCustom={isCustom(conditions)}
                    category={getCategory(conditions)}
                  />
                </ListItem>
              ) : null}
              <ListItem>
                <New
                  conditions={conditions}
                  setConditions={setConditions}
                  isNew={isNew}
                  editable={editable}
                  originalMapConditions={originalMapConditions}
                />
              </ListItem>
            </>
          )}
        </List>
      )}
    </>
  );
};

export default withStep(FormView);
