import $ from "jquery";
import { xmlToJson, JsonToXML } from "./xml2JSON";

const getSegment = script => {
  if (!script) {
    return [];
  }

  const jsonData = xmlToJson($.parseXML(script));

  if (!jsonData.segment) {
    return [];
  }

  return jsonData.segment;
};

const getFinalScript = ({ segmentData, view, script }) => {
  switch (view) {
    case "formView":
      return `<segment>\n${JsonToXML(segmentData)}</segment>`;
    case "codeView":
      return script;
    default:
      return null;
  }
};

export { getSegment, getFinalScript };
