import axios from "axios";
import {
  API_SEGMENTS_LISTS,
  API_SEGMENT_ITEM_DETAIL,
  API_CREATE_SEGMENT,
  API_UPDATE_SEGMENT,
  SEGMENT_CONDITIONS
} from "../../Utils";

const preProcessSpecificError = error => {
  // 7002 no segements in list error code
  // 7001 no segement in detail error code
  // refer: https://github.com/theplant/boost/commit/20a6431553701724c9e4b6c3e38dcc5dad8e94a2
  if (error.code === 7002 || error.code === 7001) {
    return true;
  } else {
    return false;
  }
};

// list
const getSegments = () =>
  axios
    .get(API_SEGMENTS_LISTS)
    .catch(e => {
      const error = e.response.data;
      if (preProcessSpecificError(error)) {
        return { data: { data: [] } };
      } else {
        throw e;
      }
    })
    .then(({ data }) => data);

// item detail
const getSegmentItem = id =>
  axios
    .get(API_SEGMENT_ITEM_DETAIL + id)
    .catch(e => {
      const error = e.response.data;
      if (preProcessSpecificError(error)) {
        return { data: { data: {} } };
      } else {
        throw e;
      }
    })
    .then(({ data }) => data);

const config = {
  headers: {
    "Content-Type": "application/json"
  }
};

// create
const createSegment = ({ data }) =>
  axios
    .post(API_CREATE_SEGMENT, JSON.stringify(data), config)
    .then(({ data }) => data);

// update
const updateSegment = ({ data, segmentId }) =>
  axios
    .post(API_UPDATE_SEGMENT + Number(segmentId), JSON.stringify(data), config)
    .then(({ data }) => data);

const getConditions = () =>
  axios
    .get(SEGMENT_CONDITIONS)
    .then(({ data }) => data);

export { getSegments, getSegmentItem, createSegment, updateSegment, getConditions};
