import React from "react";
import styled from "styled-components";

const Text = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const ClearAll = ({ condition, editable, handleChange }) => {
  if (!editable) {
    return null;
  }

  const handleClearAll = condition => {
    handleChange({ name: "value", value: undefined, condition });
  };

  return condition.value ? (
    <Text onClick={() => handleClearAll(condition)}>clear all</Text>
  ) : null;
};

export default ClearAll;
