import React from "react";

const useNewConditionForm = ({
  conditions,
  setConditions,
  isCustom,
  category
}) => {
  const initialState = {
    category: isCustom ? "" : category,
    path: "",
    type: "",
    op: "",
    value: "",
    event: "",
    title: "",
    subtitle: "",
    isCustom
  };

  const [addCondition, setAddCondition] = React.useState(initialState);

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === "type" && value === "EpochTimeMS") {
      setAddCondition({
        ...addCondition,
        [name]: value,
        isOffset: true
      });
    } else {
      setAddCondition({
        ...addCondition,
        [name]: value
      });
    }
  };

  const addNewCondition = () => {
    let newConditions = [...conditions, addCondition];

    newConditions = newConditions.filter(
      ({ event }) => !!event && event !== ""
    );

    setConditions(newConditions);
    // clear data
    setAddCondition(initialState);
  };

  const disabled =
    addCondition.value === "" ||
    addCondition.value === "" ||
    addCondition.path === "" ||
    addCondition.type === "" ||
    addCondition.op === "" ||
    addCondition.event === "" ||
    addCondition.title === "";

  return { addCondition, handleChange, addNewCondition, disabled };
};

export { useNewConditionForm };
