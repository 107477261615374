const getCondition = data => {
  if (!data) {
    return [];
  }

  const condition = data && data.condition;

  if (condition instanceof Array) {
    return condition;
  } else if (condition) {
    return [condition];
  }
};

const getGroups = ({ groups: _groups, item }) => {
  if (Object.entries(item).length === 0) {
    return [];
  }

  const [key, value] = Object.entries(item)[0];
  let groups = _groups;

  if (key === "condition") {
    if (
      groups.findIndex(({ condition }) => {
        if (condition instanceof Array) {
          return condition.findIndex(({ event }) => event === value.event) > -1;
        } else {
          return condition.event === value.event;
        }
      }) > -1
    ) {
      groups.map(({ conditionOrLogic, condition }, index) => {
        if (condition instanceof Array) {
          if (condition.findIndex(({ event }) => event === value.event) > -1) {
            groups.splice(index, 1, {
              conditionOrLogic,
              condition: [...condition, value]
            });
          } else {
            return groups;
          }
        } else {
          if (condition.event === value.event) {
            groups.splice(index, 1, {
              conditionOrLogic,
              condition: [condition, value]
            });
          } else {
            return groups;
          }
        }

        return groups;
      });
    } else {
      groups = [
        ...groups,
        {
          conditionOrLogic: key,
          condition: value
        }
      ];
    }
  } else {
    if (value instanceof Array) {
      let _conditions = [];
      for (let v of value) {
        _conditions = _conditions.concat(getCondition(v));
      }
      if (_conditions.length > 0) {
        groups = [
          ...groups,
          {
            conditionOrLogic: key,
            condition: _conditions
          }
        ];
      }
    } else {
      const _condition = getCondition(value);
      if (_condition) {
        groups = [
          ...groups,
          {
            conditionOrLogic: key,
            condition: getCondition(value)
          }
        ];
      }
    }
  }
  return groups;
};

const getFirstLogicTypeAndGroups = segmentData => {
  let firstLogicType;
  let groups = [];

  if (segmentData.length === 0) {
    return { firstLogicType: "and", groups: [] };
  }

  for (let firstKey in segmentData) {
    if (firstKey) {
      if (Number(firstKey) > -1 && typeof Number(firstKey) === "number") {
        firstLogicType = "condition";
        let index = firstKey;

        groups = getGroups({ groups, item: segmentData[index] });
      } else {
        firstLogicType = firstKey;
        if (segmentData[firstKey]) {
          if (segmentData[firstKey] instanceof Array) {
            for (let item of segmentData[firstKey]) {
              groups = getGroups({ groups, item });
            }
          } else {
            if (firstKey === "condition") {
              groups = getGroups({ groups, item: segmentData });
            } else {
              groups = getGroups({ groups, item: segmentData[firstKey] });
            }
          }
        }
      }
    }
  }

  return { firstLogicType, groups };
};

const getInnerData = groups => {
  let innerData = [];
  groups.forEach(({ conditionOrLogic, condition }) => {
    if (conditionOrLogic === "condition") {
      innerData = [
        ...innerData,
        {
          condition:
            condition instanceof Array && condition.length > 0
              ? condition
              : [condition]
        }
      ];
    } else {
      if (typeof innerData[conditionOrLogic] === "undefined") {
        innerData = [...innerData, { [conditionOrLogic]: { condition } }];
      } else if (innerData[conditionOrLogic] instanceof Array) {
        innerData = [
          ...innerData,
          {
            [conditionOrLogic]: innerData[conditionOrLogic].concat({
              condition
            })
          }
        ];
      } else {
        innerData = [
          ...innerData,
          { [conditionOrLogic]: [innerData[conditionOrLogic], { condition }] }
        ];
      }
    }
  });
  return innerData;
};

const cannotSave = segmentData => {
  let disabled = true;

  const { groups } = getFirstLogicTypeAndGroups(segmentData);

  if (!groups || groups.length === 0) {
    disabled = true;
  }

  groups.some(({ condition }) => {
    if (condition instanceof Array) {
      condition.some(({ value }) => {
        if (value) {
          disabled = false;
          return true;
        }
        return false;
      });
    } else {
      if (condition.value) {
        disabled = false;
        return true;
      }
    }
    return false;
  });

  return disabled;
};

export { getCondition, getFirstLogicTypeAndGroups, getInnerData, cannotSave };
