import styled from "styled-components";
import { Paper } from "@material-ui/core";

const DetailHeaderWrapper = styled(Paper)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  padding: 20px;
  margin-bottom: 10px;
`;

const ContentWrapper = styled(Paper)`
  margin-top: 10px;
`;

export { DetailHeaderWrapper, ContentWrapper };
