import React from "react";
import styled from "styled-components";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import ConfirmModal, { withConfirmModal } from "../../ConfirmModal";

const Logic = styled.span`
  background: lightblue;
  color: white;
  padding: 5px;
  border-radius: 5%;
`;

const Wrapper = styled.div`
  display: inline-block;
  margin: 10px;
  margin-left: 0px;
  cursor: pointer;
`;

const EditText = styled.span`
  padding: 5px;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
`;

const Triangle = styled.div`
  height: 8px;
  width: 8px;
  border-top: solid 8px white;
  border-left: solid 8px #ff9800;
  border-right: solid 8px white;
  border-bottom: solid 8px white;
  display: inline-block;
  transform: rotate(90deg) translate(4px, 4px);
  ${({ listIsOpen }) =>
    !listIsOpen ? `transform: rotate(0deg) translate(0px, 0px);` : ""}
`;

const PlainSelector = ({ name, value, onChange, items, style, title }) => (
  <FormControl style={style}>
    {title && <InputLabel>{title}</InputLabel>}
    <Select
      name={name}
      value={value}
      onChange={onChange}
      style={{ minWidth: 150 }}
    >
      {items.map(item => (
        <MenuItem value={item} key={item} disabled={item === value}>
          {item}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const Selector = withConfirmModal(
  ({
    open,
    clickToCollapseList,
    listIsOpen,
    handleClickOpen,
    handleClose,
    ...props
  }) => {
    const { value, title, canEdit, firstLogicType } = props;

    let items = props.items;

    if (
      firstLogicType &&
      (firstLogicType === "nand" || firstLogicType === "nor")
    ) {
      items = ["condition"];
    }

    return (
      <>
        <Wrapper onClick={clickToCollapseList}>
          <Triangle listIsOpen={listIsOpen} />
          {title}: {value ? <Logic>{value}</Logic> : null}
          {canEdit ? (
            <EditText
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handleClickOpen();
              }}
            >
              edit
            </EditText>
          ) : null}
        </Wrapper>

        <ConfirmModal
          open={open}
          content={
            <PlainSelector
              {...props}
              items={items}
              onChange={data => {
                props.onChange(data);
                handleClose();
              }}
            />
          }
          handleClose={handleClose}
          handleConfirm={handleClose}
        />
      </>
    );
  }
);

export default Selector;
export { PlainSelector };
