import React from "react";
import styled from "styled-components";
import { FormControl } from "@material-ui/core";
import { useStyles } from "../../../../style";
import Input from "./Input";
import Title from "../../atoms/Title";
import Op from "./Op";
import DatePicker, { displayDatePicker } from "../../atoms/DatePicker";
import MultipleSelectWithOP, {
  displayMultSelector
} from "./MultipleSelectWithOP";
import ClearAll from "../../atoms/ClearAll";
import { isMatched } from "../../conditionHelper";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InputGroup = props => {
  const classes = useStyles();

  const { setConditions, ...rest } = props;
  const condition = rest.condition;
  const type = props.type;
  const isOffset =
    (condition.types && condition.types === "EpochTimeMS") ||
    condition.type === "EpochTimeMS";

  const disabled = !(rest.editable || rest.condition.value);

  const handleChange = ({ name, value, condition }) => {
    setConditions(conditions => {
      if (conditions.findIndex(c => isMatched({ c, condition })) > -1) {
        return conditions.map(c =>
          isMatched({ c, condition })
            ? {
                ...c,
                [name]: value || ""
              }
            : c
        );
      } else {
        return conditions.concat([{ ...condition, [name]: value }]);
      }
    });
  };

  return (
    <Wrapper>
      <Title
        title={condition.title || condition.path}
        subtitle={condition.subtitle}
      />
      <FormControl className={classes.margin}>
        <Op
          {...rest}
          handleChange={handleChange}
          disabled={disabled}
          isOffset={isOffset}
        />
      </FormControl>
      <FormControl className={classes.margin}>
        {displayDatePicker({ type }) ? (
          <DatePicker
            {...rest}
            handleChange={handleChange}
            disabled={disabled}
          />
        ) : displayMultSelector({
            type,
            op: condition.op
          }) ? (
          <MultipleSelectWithOP
            {...rest}
            handleChange={handleChange}
            disabled={disabled}
          />
        ) : (
          <Input
            {...rest}
            isOffset={isOffset}
            handleChange={handleChange}
            disabled={disabled}
          />
        )}
      </FormControl>
      <ClearAll {...rest} handleChange={handleChange} disabled={disabled} />
    </Wrapper>
  );
};

export default InputGroup;
