import React from "react";
import CodeView from "./CodeView";
import FormView from "./FormView";
import styled from "styled-components";
import { AppBar, Tabs, Tab } from "@material-ui/core";

const ConditionsTitle = styled.div`
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
`;

const silmStyle = `
    margin: 0 20px;
    width: calc(100% - 2 * 20px);
`;

const AppBarHeader = styled(AppBar)`
  &&& {
    ${silmStyle}
  }
`;

const CodeViewWrapper = styled.div`
  ${silmStyle}
`;

// Choose Purchaase History condition and fill up the conditions you need.
// The conditions can be deleted.
const FormOrCodeView = ({
  script,
  setScript,
  isNew,
  view,
  setFormView,
  editable,
  switchToCodeView
}) => (
  <>
    <ConditionsTitle>
      <h2>Conditions</h2>
    </ConditionsTitle>
    <AppBarHeader position="static">
      <Tabs value={view} aria-label="switch view" centered>
        <Tab label="FormView" onClick={setFormView} value="formView" />
        <Tab label="CodeView" onClick={switchToCodeView} value="codeView" />
      </Tabs>
    </AppBarHeader>
    {view === "formView" ? (
      <FormView editable={editable} isNew={isNew} />
    ) : (
      <CodeViewWrapper>
        <CodeView
          value={script}
          onChange={editable ? setScript : undefined}
          isNew={isNew}
        />
      </CodeViewWrapper>
    )}
  </>
);

export default FormOrCodeView;
