import styled from "styled-components";

const EventLabel = styled.span`
  background: lightcoral;
  color: white;
  padding: 5px;
  border-radius: 5%;
`;

export { EventLabel };
