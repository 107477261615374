import React from "react";
import styled from "styled-components";
import { InputLabel as _InputLabel } from "@material-ui/core";

const InputLabel = styled(_InputLabel)`
  min-width: 200px;
  max-width: 200px;

  &&& {
    display: flex;
    flex-direction: column;
  }

  .smallLabel {
    padding-top: 3px;
  }
`;

const Title = ({ title, subtitle }) => (
  <InputLabel>
    {title}
    {subtitle && <small className="smallLabel">{subtitle}</small>}
  </InputLabel>
);

export default Title;
