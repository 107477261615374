import { useEffect, useContext, useReducer } from "react";
import { usePrevious } from "../../../hooks/usePrevious";
import { SegmentDataContext } from "../../../hooks/SegmentContext";
import { reducer, init, getFirstLogicTypeAndGroups } from "./reducer";
import _ from "lodash";

const useSegmentData = () => {
  const { segmentData, setSegmentData } = useContext(SegmentDataContext);
  const myPreviousState = usePrevious(segmentData);

  const [state, dispatch] = useReducer(reducer, segmentData, init);

  const { firstLogicType, groups } = getFirstLogicTypeAndGroups(
    state.segmentData
  );

  const changeGroups = ({ index, name, value }) => {
    const { groups } = getFirstLogicTypeAndGroups(state.segmentData);

    const newGroup = groups.map((g, _index) =>
      _index === index ? { ...g, [name]: value } : g
    );

    updateGroups(newGroup);
  };

  const updateGroups = newGroup => {
    dispatch({
      type: "groups",
      payload: newGroup
    });
  };

  const handleChange = index => ({ target: { value, name } }) => {
    changeGroups({ index, name, value });
  };

  const handleChildChange = ({ index, value, name }) => {
    changeGroups({ index, name, value });
  };

  const changeFirstLogicType = ({ target: { value, name } }) => {
    dispatch({ type: name, payload: value });
  };

  useEffect(() => {
    if (setSegmentData) {
      if (myPreviousState && !_.isEqual(myPreviousState, state.segmentData)) {
        setSegmentData(state.segmentData);
      }
    }
  }, [state.segmentData, myPreviousState, setSegmentData]);

  return {
    firstLogicType,
    setFirstLogicType: changeFirstLogicType,
    groups,
    handleChange,
    handleChildChange,
    updateGroups
  };
};

export { useSegmentData };
