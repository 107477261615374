const getEvent = conditions =>
  conditions && conditions.length > 0 && conditions[0].event;

const getCategory = conditions =>
  conditions && conditions.length > 0 && conditions[0].category;

const isCustom = conditions =>
  conditions &&
  conditions.length > 0 &&
  conditions.some(({ isCustom }) => !!isCustom);

const getDisplayConditions = (conditions, originalMapConditions) => {
  const category = getCategory(conditions);
  const event = getEvent(conditions);
  let matchConditions = originalMapConditions[category];
  if (!matchConditions || (matchConditions.length === 0 && event)) {
    matchConditions = originalMapConditions[event];
  }

  matchConditions = (matchConditions || []).map(c => {
    const find = conditions.find(({ path }) => path === c.path);

    // need to display some unmatched values in codeView
    if (find) {
      const findIndex = conditions.findIndex(({ path }) => path === find.path);

      if (findIndex > -1) {
        conditions.splice(findIndex, 1);
      }
    }

    return { ...c, ...find };
  });

  return matchConditions.concat(conditions);
};

const isMatched = ({ c, condition }) =>
  c.path === condition.path && c.event === condition.event;

export { getEvent, getCategory, getDisplayConditions, isMatched, isCustom };
