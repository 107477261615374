const uniqueArr = arr => {
  if (!Array.isArray(arr)) {
    console.log("type error!");
    return;
  }
  const result = [];
  let obj = {};
  for (var i = 0; i < arr.length; i++) {
    if (!obj[arr[i].value]) {
      result.push(arr[i]);
      obj[arr[i].value] = arr[i].label;
    }
  }
  return result;
};

const groupBy = (xs, key) =>
  xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

export { uniqueArr, groupBy };
