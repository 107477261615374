import { useState } from "react";

const useFormOrCodeView = defaultView => {
  const [view, setView] = useState(defaultView || "formView"); // formView, or codeView

  const setFormView = () => setView("formView");
  const setCodeView = () => setView("codeView");

  return {
    view,
    setFormView,
    setCodeView
  };
};

export default useFormOrCodeView;
