import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  addBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    margin: 10
  },
  finishBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    margin: 20
  },
  codeViewConfirmBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    margin: "20px 0"
  },
  saveBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    margin: 10
  },
  editBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    margin: 10
  },
  discardBtn: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.contrastText,
    margin: 10,
    border: `1px solid ${theme.palette.primary.dark}`
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: 50,
    textAlign: "center"
  },
  margin: {
    margin: "0 10px"
  },
  textAlignRight: {
    textAlign: "right"
  }
}));

export { useStyles };
