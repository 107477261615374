import React from "react";
import MultipleSelect from "../../atoms/MultipleSelect";

const displayMultSelector = ({ type, op }) =>
  type === "MultipleSelect" ||
  ["containsany", "notcontainsany"].findIndex(_op => _op === op) > -1;

const MultipleSelectWithOP = props => {
  const { condition, options, handleChange } = props;
  const values = condition.value && condition.value.split(",");

  const selectedOptions = [];
  (values || []).forEach(v => {
    selectedOptions.push({ value: v, label: v });
  });
  const placeholder = condition.placeholder && condition.placeholder.split(",");
  const placeholderOptions = [];
  (placeholder || []).forEach(v => {
    placeholderOptions.push({ value: v, label: v });
  });

  const onChange = newValue => {
    let datas = [];
    (newValue || []).forEach(v => datas.push(v.value));

    handleChange({ name: "value", value: datas.join(","), condition });
  };

  return (
    <MultipleSelect
      name="value"
      options={options}
      placeholder={placeholderOptions}
      defaultValues={selectedOptions}
      onChange={onChange}
      editable={props.editable}
      localStorageKey={condition.path} // seperate saved user custom options in cookie
    />
  );
};

export default MultipleSelectWithOP;
export { displayMultSelector };
