import React from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from "@material-ui/core";

const THEAD_ROWS = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  {
    id: "visitor_count",
    numeric: false,
    disablePadding: false,
    label: "Visitor Count"
  },
  {
    id: "updated_at",
    numeric: false,
    disablePadding: false,
    label: "Last Updated"
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created By"
  }
];

const EnhancedTableHead = ({ sort: { order, orderBy }, onRequestSort }) => (
  <TableHead>
    <TableRow>
      {THEAD_ROWS.map(row => (
        <TableCell
          key={row.id}
          numeric={row.numeric ? "true" : "false"}
          padding={row.disablePadding ? "none" : "default"}
          sortDirection={order === row.id ? order : false}
        >
          <Tooltip
            title="Sort"
            placement={row.numeric ? "bottom-end" : "bottom-start"}
            enterDelay={300}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={() => onRequestSort(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </Tooltip>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired
};

export default EnhancedTableHead;
